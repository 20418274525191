export default {
  items: [
    {
      name: 'Invoices',
      url: '/vendor',
      icon: 'fa fa-layer-group',
    },
    {
      name: 'Create Invoice',
      url: '/vendor/create-invoice',
      icon: 'fa fa-plus-circle',
    },
    {
      name: 'Preset Invoices',
      url: '/vendor/presets',
      icon: 'fa fa-sticky-note',
    },
    {
      name: 'My Profile',
      url: '/vendor/profile',
      icon: 'fa fa-user',
    },
    {
      name: 'Company Logo',
      url: '/vendor/company-logo',
      icon: 'fa fa-user',
    },   
    // {
    //   name: 'Add Sub Vendor',
    //   url: '/vendor/new',
    //   icon: 'fa fa-plus'
    // }, 
    {
      name: 'Vendor Guide',
      url: '',
      icon: 'fa fa-book',
      id: 'guide',
    },
    {
      name:'Download Price Book Template',
      url: '',
      icon: 'fa fa-download',
      id: 'vendorPriceBook'
    },
    {
      name:'Download Invoice Template',
      url: '',
      icon: 'fa fa-download',
      id: 'vendorImportInvoice'
    },
    
  ]
}
