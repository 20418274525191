<template>
  <nav class="sidebar-nav">
    <VuePerfectScrollbar class="scroll-area" :settings="psSettings" @ps-scroll-y="scrollHandle">
      <ul class="nav">
        <template v-for="(item, index) in navItems">
          <template v-if="item.title">
            <SidebarNavTitle :key="index" :name="item.name" :classes="item.class" :wrapper="item.wrapper" />
          </template>
          <template v-else-if="item.divider">
            <SidebarNavDivider :key="index" :classes="item.class" />
          </template>
          <template v-else-if="item.label">
            <SidebarNavLabel :key="index" :name="item.name" :url="item.url" :icon="item.icon" :label="item.label"
              :classes="item.class" />
          </template>
          <template v-else>
            <template v-if="item.children">
              <!-- First level dropdown -->
              <SidebarNavDropdown :key="index" :name="item.name" :url="item.url" :icon="item.icon">
                <template v-for="(childL1, index1) in item.children">
                  <template v-if="childL1.children">
                    <!-- Second level dropdown -->
                    <SidebarNavDropdown :key="index1" :name="childL1.name" :url="childL1.url" :icon="childL1.icon">
                      <li :key="index2" class="nav-item" v-for="(childL2, index2) in childL1.children">
                        <SidebarNavLink :name="childL2.name" :url="childL2.url" :icon="childL2.icon"
                          :badge="childL2.badge" :variant="childL2.variant" :attributes="childL2.attributes" />
                      </li>
                    </SidebarNavDropdown>
                  </template>
                  <template v-else>
                    <SidebarNavItem :key="index1" :classes="item.class">
                      <SidebarNavLink :name="childL1.name" :url="childL1.url" :icon="childL1.icon"
                        :badge="childL1.badge" :variant="childL1.variant" :attributes="childL1.attributes" />
                    </SidebarNavItem>
                  </template>
                </template>
              </SidebarNavDropdown>
            </template>
            <template v-else>
              <SidebarNavItem :key="index" :classes="item.class">
                <SidebarNavLink v-on:click.native="loadGuide(item)" :name="item.name" :url="item.url" :icon="item.icon"
                  :badge="item.badge" :variant="item.variant" :attributes="item.attributes" />
              </SidebarNavItem>
            </template>
          </template>

        </template>
      </ul>
      <slot></slot>
    </VuePerfectScrollbar>

    <b-modal ok-only scrollable id="guide-modal" size="xl" ref="guide-modal" title="Vendor Guide (Scroll For More)">
      <img class="w-100" src="/guides/Vendors guide-page-001.jpg" />
      <img class="w-100" src="/guides/Create Invoice with comments.png" />
      <img class="w-100" src="/guides/Vendors guide-page-004.jpg" />
      <img class="w-100" src="/guides/Line item edit.png" />
      <img class="w-100" src="/guides/Coding.png" />
      <img class="w-100" src="/guides/Submit.png" />
    </b-modal>

    <b-modal id="vendorPriceBook" ref="vendorPriceBook" title="Download Price book.">
      <a href="/templates/Service-Vendor-Import-Template.csv" class="btn btn-primary">Download Template <i
          class="fa fa-file"></i></a>
    </b-modal>

    <b-modal id="vendorImportInvoice" ref="vendorImportInvoice" title="Download Invoice Template">
      <b-container>
        <b-row>
          <a href="/templates/ImportInvoiceTemplate.xlsx" class="btn btn-primary">
            Download Excel Template <i class="fa fa-file"></i>
          </a>
        </b-row>
        <b-row v-if="environment == 'production'">
          <a :href="xmlURL" class="btn mt-2 btn-primary">
            Download XML Template <i class="fa fa-file"></i>
          </a>
        </b-row>
        <b-row v-else>
          <a :href="xmlURL" class="btn mt-2 btn-primary">
            Download XML Template (development) <i class="fa fa-file"></i>
          </a>
        </b-row>
      </b-container>
    </b-modal>
  </nav>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { backend_url } from '../config';

import { SidebarNavDivider, SidebarNavDropdown, SidebarNavLink, SidebarNavTitle, SidebarNavItem, SidebarNavLabel } from '@coreui/vue'

export default {
  name: 'SidebarNav',
  components: {
    SidebarNavDivider,
    SidebarNavDropdown,
    SidebarNavLink,
    SidebarNavTitle,
    SidebarNavItem,
    SidebarNavLabel,
    VuePerfectScrollbar,
  },
  props: {
    navItems: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data() {
    return {
      backend_url: backend_url,
      guide: false,
    }
  },
  computed: {
    psSettings: () => {
      // ToDo: find better rtl fix
      return {
        maxScrollbarLength: 200,
        minScrollbarLength: 40,
        suppressScrollX: getComputedStyle(document.querySelector('html')).direction !== 'rtl',
        wheelPropagation: false,
        interceptRailY: styles => ({ ...styles, height: 0 })
      }
    },
    environment: () => backend_url === "http://localhost:3000" ? "development" : "production",
    xmlURL: () => backend_url === "http://localhost:3000" ?
      `${backend_url}/vendor/get/xml_template/development` :
      `${backend_url}/vendor/get/xml_template/production`,
  },
  methods: {
    loadGuide(guide) {
      if (guide.id == "guide") {
        this.$refs['guide-modal'].show();
      } else if (guide.id == 'vendorPriceBook') {
        this.DownloadPriceBook();
      } else if (guide.id === 'vendorImportInvoice') {
        this.$refs['vendorImportInvoice'].show();
      }
    },
    DownloadPriceBook() {
      this.$refs['vendorPriceBook'].show();
    },
    scrollHandle(evt) {
      // console.log(evt)
    },
  }
}
</script>

<style scoped lang="css">
.scroll-area {
  position: absolute;
  height: 100%;
  margin: auto;
}
</style>
