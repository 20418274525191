<template>
  <AppHeaderDropdown right no-caret>
    <template slot="header">
      <div class='float-right position-relative'>
        <p class="text-left pb-0 mb-0 d-none d-md-block ml-2 mr-5 font-weight-bold h5">{{account_name}}</p>
        <i class="d-none d-md-block fa fa-chevron-down dropdown-icon"></i>
        <p class="d-none d-md-block mb-0 pb-0 ml-2 mr-5 text-left">(Vendor)</p>
      </div>
      <div v-if="vendorAvatar" class="float-right d-none d-md-block mr-2 avatar-header-logo">
        <img v-bind:src="vendorAvatar" />
      </div>
      <div v-if="vendorAvatar" class="float-right d-md-none mr-2 avatar-header-logo">
        <img
          src="img/avatars/6.jpg"
          class="img-avatar"
        />
      </div>
      <div v-else class='float-right mr-2'><img
        src="img/avatars/6.jpg"
        class="img-avatar"
      /></div>

    </template>

    <template slot="dropdown">
      <b-dropdown-header tag="div" class="text-center"><strong>Account</strong></b-dropdown-header>
      <b-dropdown-item :to="{ path: '/vendor/profile' }"><i class="fa fa-user" /> Profile</b-dropdown-item>
      <b-dropdown-item :to="{ path: '/vendor/password' }"><i class="fa fa-lock" /> Password</b-dropdown-item>
      <b-dropdown-item v-on:click="logout()"><i class="fa fa-sign-out" /> Logout</b-dropdown-item>
    </template>
  </AppHeaderDropdown>
</template>

<script>
import * as jwt_decode from 'jwt-decode'
import { HeaderDropdown as AppHeaderDropdown } from '@coreui/vue'
export default {
  name: 'VendorDropdownAccnt',
  components: {
    AppHeaderDropdown
  },
  data: () => {
    return {
      account_name: "",
      account_type: "",
      vendorAvatar: "",
    }
  },
  beforeCreate() {
    if(this.$session.get('user') !== 'vendor'){
      this.$session.destroy();
      this.flash({ message: 'UNAUTHORIZED', variant: 'danger' });
      this.$router.push('/');
    }
  },
  mounted() {
    let token = this.$session.get('jwt');
    var decoded = jwt_decode(token);

    this.$http.get('/get/vendor/avatar/' + decoded.id ).then(response => {
      this.vendorAvatar = response.data.avatar;
    }).catch(error => {
      console.log("Error getting avatar " + error);
    })
    this.$http.get('/vendor/get/profile')
    .then(response => {
      this.account_name = response.data.fname + " " + response.data.lname;
      this.account_type = this.$session.get('user');
    })
    .catch(error => {

    })
  },
  methods: {
    logout: function () {
      this.$session.destroy()
      this.flash({ message: 'Logout Successful', variant: 'success' });
      this.$router.push('/')
    }
  }
}
</script>
